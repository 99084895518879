import {
  pino,
  type DestinationStream,
  type Level,
  type Logger,
  type LoggerOptions,
} from 'pino';

export class L {
  private static _instance: Logger;

  public static init(config: LoggerOptions, stream?: DestinationStream) {
    this._instance = pino(config, stream);
  }

  public static get instance(): Logger {
    if (this._instance == null) {
      throw new Error(
        'Logger is not initialized. Did you forget to call `init`?',
      );
    }
    return this._instance;
  }
}

/**
 * A callback or callback map that is used by pino to redirect logs to.
 */
export type LoggerWriter = NonNullable<LoggerOptions['browser']>['write'];
/**
 * The config that is supposed to run in the service worker environment.
 *
 * For example, browser background scripts are executed in the service worker environment.
 */
export function createServiceWorkerConfig({
  logLevel = 'info',
  serializeErrors = false,
  writer = undefined,
}: {
  logLevel: Level;
  serializeErrors: boolean;
  writer: LoggerWriter;
}): LoggerOptions {
  return {
    level: logLevel,
    browser: {
      asObject: true,
      serialize: serializeErrors,
      formatters: {
        level: (label: string) => {
          return { level: label };
        },
      },
      write: writer,
    },
  };
}

export interface ServerConfig extends LoggerOptions {
  serviceName: string;
  appEnvironment: 'local' | 'dev' | 'prod' | 'demo';
  grafanaLokiUrl?: string;
  grafanaLokiUsername?: string;
  grafanaLokiApiKey?: string;
  enablePrettyPrint?: boolean;
}

export function createServerConfig(config: ServerConfig): LoggerOptions {
  const { enablePrettyPrint = false } = config;
  return enablePrettyPrint
    ? getPinoPrettyServerConfig(config)
    : getProdServerConfig(config);
}

function getPinoPrettyServerConfig(config: ServerConfig): LoggerOptions {
  return {
    ...config,
    transport: {
      target: 'pino-pretty',
      options: {
        colorize: true,
        levelFirst: true,
      },
    },
  };
}

function getProdServerConfig(config: ServerConfig): LoggerOptions {
  const {
    appEnvironment,
    serviceName,
    grafanaLokiUsername,
    grafanaLokiUrl,
    grafanaLokiApiKey,
  } = config;
  return {
    ...config,
    name: serviceName,
    // Using `formatters` attribute is not allowed with custom transports
    // we can workaround it with a mixin object
    mixin(_context) {
      const timestamp =
        'time' in _context ? (_context.time as number) : Date.now();
      const formattedTime = new Date(timestamp).toISOString();
      return { formattedTime };
    },
    transport: {
      target: 'pino-loki',
      options: {
        labels: {
          environment: appEnvironment,
          service_name: serviceName,
        },
        batching: true,
        interval: 5,
        host: grafanaLokiUrl,
        basicAuth: {
          username: grafanaLokiUsername,
          password: grafanaLokiApiKey,
        },
      },
    },
  };
}

export * from './sentry/index';
