import { type Scope, type SeverityLevel } from '@sentry/browser';

/**
 * Transmits a log event generated by Pino to Sentry.
 *
 * This function is supposed to be used in the service worker environment (like background script of browser extensions).
 * @param sentry - The Sentry scope since  Sentry.init() is not called in the service worker environment.
 * @param logEvent - The log event generated by Pino.
 * @example
 * // Example of logEvent:
 * {
 *  "time": 1717757442954,
 * 	"level": "debug",
 * 	"msg": "Retreiving cookie"
 * }
 * // Example of error logEvent:
 * {
 * 	"time": 1717758185093,
 * 	"level": "error",
 * 	"type": "Error",
 * 	"msg": "Error only",
 * 	"stack": "Error: Error only\n    at handler (chrome-extension://kmnkibpgmkndpeedonfffagenmboiici/static/background/index.js:2335:35)"
 * }
 */
export function sentryLogsWriter(sentry: Scope, logEvent: object) {
  let sentryLevel: SeverityLevel;
  if ('level' in logEvent && typeof logEvent.level === 'string') {
    sentryLevel = mapToSentryLevel(logEvent.level);
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      `Cannot extract log level from log event. Property 'level' is missing or not a string. Using default 'log' level instead.`,
    );
    sentryLevel = 'log';
  }
  if (canReconstructError(logEvent)) {
    const err = new Error(logEvent.msg);
    err.stack = logEvent.stack;
    sentry.addBreadcrumb({
      type: 'error',
      level: sentryLevel,
      data: logEvent,
      message: logEvent.msg,
    });
    return sentry.captureException(err, { data: logEvent });
  }

  let msg: string;
  if ('msg' in logEvent && typeof logEvent.msg === 'string') {
    msg = logEvent.msg;
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      `Cannot extract message from log event. Property 'msg' is missing or not a string. Using default message instead.`,
    );
    msg = 'No message';
  }

  sentry.addBreadcrumb({ level: sentryLevel, data: logEvent, message: msg });
}

function mapToSentryLevel(level: string): SeverityLevel {
  switch (level) {
    case 'fatal':
    case 'error':
      return 'error';
    case 'warn':
      return 'warning';
    case 'info':
      return 'info';
    case 'trace':
    case 'debug':
      return 'debug';
    case 'log':
      return 'log';
    default:
      // eslint-disable-next-line no-console
      console.warn(
        `Cannot map Pino log level to Sentry level. Unknwon level: ${level}. Using default 'log' instead.`,
      );
      return 'log';
  }
}

function canReconstructError(
  event: unknown,
): event is { type: string; msg: string; stack: string } {
  return (
    event != null &&
    typeof event === 'object' &&
    'type' in event &&
    event.type === 'Error' &&
    'msg' in event &&
    event.msg != null &&
    'stack' in event &&
    event.stack != null
  );
}
